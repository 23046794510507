























































































import { Vue, Component } from 'vue-property-decorator'

@Component
export default class PrivacyPage extends Vue {
  // COPMPUTED
  get paddings(): string {
    return window.paddings(this.$vuetify.breakpoint);
  }

}
