























































































































import { Vue, Component } from 'vue-property-decorator'

@Component
export default class PrivacyPage extends Vue {
  // COMPUTED
  get paddings(): string {
    return window.paddings(this.$vuetify.breakpoint);
  }
}
